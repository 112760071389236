<template>
  <div class="input-container">

    <div class="label-container">
      <FormLabel
        v-if="showLabel"
        :formName="formName"
        :fieldName="fieldName"
      />
      <slot name="label-extra-content" />
    </div>

    <FormLabelDescription
      :formName="formName"
      :fieldName="fieldName"
      v-if="field.labelDescription"
    />

    <template v-if="type === 'content-switcher'">
      <div ref="contentSwitcher" class="content-switcher">
        <div ref="contentSwitcherBackground" class="content-switcher-background" />
        <button
          class="small"
          type="button"
          :key="option.id"
          v-for="option in field.options"
          @click="onButtonGroupOptionClick(option)"
          :disabled="field.disabled || option.disabled"
          :class="{ 'active': field.value === option.value }"
        >
          {{ option.label }}
          <span class="ml-1 text-accent" v-if="option.accentText">{{ option.accentText }}</span>
        </button>
      </div>
    </template>
    <template v-else-if="type === 'button-group'">
      <div class="button-group">
        <button
          type="button"
          :key="option.id"
          class="small filter"
          v-for="option in field.options"
          @click="onButtonGroupOptionClick(option)"
          :disabled="field.disabled || option.disabled"
          :class="{ 'active': field.value === option.value }"
        >
          {{ option.label }}
          <span class="ml-1 text-accent" v-if="option.accentText">{{ option.accentText }}</span>
        </button>
      </div>
    </template>
    <template v-else>
      <div
        :key="option.id"
        class="option-container"
        v-for="(option, index) in field.options"
      >
        <input
          @click.stop
          ref="formElement"
          :type="field.type"
          v-model="field.value"
          :value="option.value"
          :id="`${uuid}-${index}`"
          :required="field.required"
          @change="$emit('change', field)"
          :class="{ 'error': !!field.error }"
          :disabled="field.disabled || option.disabled"
        />
        <label
          @click.stop
          class="label-text"
          :for="`${uuid}-${index}`"
          :class="{ 'disabled': field.disabled || option.disabled }"
        >
          {{ option.label }}
          <span class="text-accent" v-if="option.accentText">{{ option.accentText }}</span>
        </label>
      </div>
    </template>

    <FormNote
      v-if="field.note"
      :formName="formName"
      :fieldName="fieldName"
    />

    <FormError
      v-if="showError"
      :formName="formName"
      :fieldName="fieldName"
    />

  </div>
</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  import FormNote from '@/components/forms/FormNote.vue'
  import FormError from '@/components/forms/FormError.vue'
  import FormLabel from '@/components/forms/FormLabel.vue'
  import FormLabelDescription from '@/components/forms/FormLabelDescription.vue'

  export default {
    emits: ['change'],
    components: {
      FormNote,
      FormError,
      FormLabel,
      FormLabelDescription,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      showError: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        validator: (value) => {
          return value === 'content-switcher' || value === 'button-group'
        },
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
    watch: {
      'field.value': function fieldValue(newValue, oldValue) {
        this.updateContentSwitcherBackground()
      },
    },
    mounted() {
      this.updateContentSwitcherBackground()
    },
    methods: {
      updateContentSwitcherBackground() {

        if (!this.$refs.contentSwitcher || !this.$refs.contentSwitcherBackground) return

        const selectedIndex = this.field.options.findIndex((option) => {
          return option.id === this.field.value
        })

        if (selectedIndex === -1) return

        const $activeButton = this.$refs.contentSwitcher.querySelector(`button:nth-of-type(${selectedIndex + 1})`)

        this.$refs.contentSwitcherBackground.style.left = `${$activeButton.offsetLeft}px`
        this.$refs.contentSwitcherBackground.style.width = `${$activeButton.offsetWidth}px`

      },
      onButtonGroupOptionClick(option) {

        const oldValue = this.field.value

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue: option.value,
        })

        if (oldValue !== this.field.value) {
          this.$emit('change', this.field)
        }

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .label-text
    @apply ml-2
    @apply mb-0
    @apply text-base
    @apply font-normal
    @apply align-bottom
    @apply text-gray-800
    @apply cursor-pointer

    &.disabled
      @apply opacity-50
      @apply cursor-not-allowed

</style>
