<template>
  <BasicModal
    :action="submitForm"
    :back-action="onBack"
    :close-on-action="false"
    action-title="Add contract"
    :show-back-button="!errorMessage && isUnverified"
    :cancel-title="errorMessage ? 'Close' : 'Cancel'"
    :disable-action-button="shouldDisableActionButton"
    :show-action-button="!errorMessage && !existingUserContract"
    :show-cancel-button="!errorMessage && !existingUserContract"
  >
    <!-- show error states if there's an errorMessage -->
    <template v-if="errorMessage">
      <div class="header">
        <ErrorIcon />
        <h4>Oops!</h4>
      </div>
      <template v-if="errorMessageType === 'no events'">
        <p>
          This smart contract does not emit any events and/or has no functions
          and therefore can not be monitored.
          <br>
          <br>
          Please try another smart contract.
        </p>
      </template>
      <template v-else>
        <p>
          Something went wrong:
          <br>
          <br>
          {{ errorMessage }}
        </p>
      </template>
    </template>

    <!--
      if no error states, show the "you've already added that contract" step
      if they've submitted the initial form already and we've detected the
      contract has already been added
    -->
    <template v-else-if="existingUserContract">
      <LoadingOverlay v-if="isLoading" message="Renaming contract..." />
      <p>
        It looks like you already added that contract as <strong>{{ existingUserContract.name }}</strong>.
        <br>
        <br>
        Would you like to update the {{ contractNicknameLabel }} to be <strong>{{ formFields.contractNickname.value }}</strong>?
      </p>
      <div class="mt-6 space-x-2 flex justify-end">
        <button class="small tertiary" @click="renameUserContract(shouldUpdatefalse)">No thanks</button>
        <button class="small" @click="renameUserContract(true)">Yes, update it</button>
      </div>
    </template>

    <!--
      if no error states, show the "unverified contract" step if they've
      submitted the initial form already and we've detected the contract is
      unverified
    -->
    <template v-else-if="isUnverified">
      <LoadingOverlay v-if="isLoading" message="Locating contract..." />
      <h5>Add the contract ABI</h5>
      <div class="alert">
        <span class="font-mono">{{ formFields.ambiguousContractAddress.value }}</span>
        is an unverified contract on {{ selectedNetworkOption.apiRecord.name }}.
      </div>
      <p class="mt-4 mb-6">
        To monitor activity from this contract, we need some more info.
      </p>
      <FormRadio :formName="formName" fieldName="isProxyContract" />

      <!-- make sure a value for isProxyContract is chosen before showing any more fields -->
      <template v-if="formFields.isProxyContract.value !== ''">

        <!-- if the ambiguousContractAddress should be considered the proxy contract address... -->
        <template v-if="formFields.isProxyContract.value">

          <!-- ...ask them if they want to track activity in the proxy and/or implementation contract -->
          <FormCheckbox :formName="formName" fieldName="trackActivityIn" />

          <!-- if they want to monitor events in the proxy contract... -->
          <template v-if="formFields.trackActivityIn.value.includes('proxy')">
            <FormInput :formName="formName" fieldName="proxyContractAddress" ref="proxyContractAddress">
              <template #label-extra-content>
                <span class="badge purple subtle mx-2">PROXY</span>
              </template>
            </FormInput>
            <FormInput :formName="formName" fieldName="proxyContractABIJSON" ref="proxyContractABIJSON">
              <template #label-extra-content>
                <span class="badge purple subtle mx-2">PROXY</span>
              </template>
            </FormInput>
          </template>

          <!-- and/or if they want to monitor events in the implementation contract... -->
          <template v-if="formFields.trackActivityIn.value.includes('implementation')">
            <FormInput :formName="formName" fieldName="implementationContractAddress" ref="implementationContractAddress">
              <template #label-extra-content>
                <span class="badge purple subtle mx-2">IMPLEMENTATION</span>
              </template>
            </FormInput>
            <FormInput :formName="formName" fieldName="implementationContractABIJSON" ref="implementationContractABIJSON">
              <template #label-extra-content>
                <span class="badge purple subtle mx-2">IMPLEMENTATION</span>
              </template>
            </FormInput>
          </template>

        </template>

        <!-- if the ambiguousContractAddress should be considered the implementation contract address... -->
        <template v-else>
          <FormInput :formName="formName" fieldName="implementationContractABIJSON" ref="implementationContractABIJSON" />
        </template>

      </template>

    </template>

    <!--
      if no errors and contract is not yet known to be unverified, show the
      initial "create contract" form
    -->
    <template v-else>
      <LoadingOverlay v-if="isLoading" message="Locating contract..." />
      <h5>Add a smart contract</h5>
      <p class="mb-6">
        Use event emissions and/or function calls to trigger alerts and workflows.
      </p>
      <FormSelect :formName="formName" fieldName="networkId" ref="networkId" />
      <FormInput :formName="formName" fieldName="ambiguousContractAddress" ref="ambiguousContractAddress" />
      <FormInput :formName="formName" fieldName="contractNickname">
        <template #label-extra-content>
          <Tooltip class="self-center">
            <template #trigger>
              <InfoIcon />
            </template>
            <p>
              Sometimes contracts have generic names like "ProxyContract".
            </p>
            <p>
              Give your contract a nickname you'll remember later so you don't
              forget what the contract is for. You can change it later.
            </p>
          </Tooltip>
        </template>
      </FormInput>
    </template>

  </BasicModal>
</template>

<script>

  import { ref } from 'vue'
  import { useStore } from 'vuex'

  import useABI from '@/composables/useABI'
  import useForm from '@/composables/useForm'

  import InfoIcon from '@/assets/icons/info.svg'
  import ErrorIcon from '@/assets/icons/error.svg'

  import FormInput from '@/components/forms/FormInput.vue'
  import FormRadio from '@/components/forms/FormRadio.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      Tooltip,
      InfoIcon,
      ErrorIcon,
      FormInput,
      FormRadio,
      FormSelect,
      BasicModal,
      FormCheckbox,
      LoadingOverlay,
    },
    props: {
      networkId: {
        type: String,
        default: '',
      },
      onSuccess: {
        type: Function,
        default: Function.prototype,
      },
    },
    setup(props) {

      // data
      const isLoading = ref(false)
      const errorMessage = ref(null)
      const isUnverified = ref(false)
      const existingUserContract = ref(null)
      const formName = 'createUserContractForm'

      // composables
      const store = useStore()
      const { parseABI } = useABI()
      const { form, isFormValid, focusElement } = useForm({ formName })

      store.commit('forms/SET_FIELD_OPTIONS', {
        newOptions: store.state.app.triggerNetworkOptionsSlugMap['smart-contract-activity'],
        fieldName: 'networkId',
        formName,
      })

      if (props.networkId) {
        store.commit('forms/SET_FIELD_VALUE', {
          newValue: props.networkId,
          fieldName: 'networkId',
          formName,
        })
      }

      return {
        form,
        formName,
        parseABI,
        isLoading,
        isFormValid,
        focusElement,
        errorMessage,
        isUnverified,
        existingUserContract,
        contractNicknameLabel: form.value.fields.contractNickname.label.toLowerCase(),
      }

    },
    computed: {
      formFields() {
        return this.form.fields
      },
      errorMessageType() {

        const errorMessage = this.errorMessage.toLowerCase()

        if (errorMessage.includes('doesn\'t appear to contain any events')) return 'no events'

        return 'other'

      },
      selectedNetworkOption() {

        if (!this.formFields.networkId.value) return null

        const selectedOption = this.formFields.networkId.options.find((option) => {
          return option.value === this.formFields.networkId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      shouldDisableActionButton() {
        return (
          this.isLoading
          || !this.isFormValid
          || (this.isUnverified && this.formFields.isProxyContract.value === '')
          || (this.isUnverified && this.formFields.isProxyContract.value === true && this.formFields.trackActivityIn.value.length === 0)
        )
      },
      isProxyRequired() {
        if (!this.isUnverified) return false
        if (this.formFields.isProxyContract.value === false) return false
        return this.formFields.trackActivityIn.value.includes('proxy')
      },
      isImplementationRequired() {
        if (!this.isUnverified) return false
        if (this.formFields.isProxyContract.value === false) return true
        return this.formFields.trackActivityIn.value.includes('implementation')
      },
    },
    watch: {
      'formFields.isProxyContract.value': function isProxyContractValue(newValue, oldValue) {

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'trackActivityIn',
          formName: this.formName,
        })
        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'implementationContractAddress',
          formName: this.formName,
        })
        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'proxyContractAddress',
          formName: this.formName,
        })

        if (newValue || newValue === '') return

        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'implementationContractAddress',
          formName: this.formName,
          newValue: true,
        })
        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'implementationContractABIJSON',
          formName: this.formName,
          newValue: true,
        })

        this.$nextTick(() => {

          // @NOTE: we delay setting the value by one tick so the watchers in
          //  the useFormField composable have time to do their thing
          //
          //  otherwise the "valid" icon doesn't appear unless the user edits
          //  the field in some way
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'implementationContractAddress',
            formName: this.formName,
            newValue: this.formFields.ambiguousContractAddress.value,
          })

          if (this.$refs.implementationContractABIJSON) {
            this.$refs.implementationContractABIJSON.focus()
          }

        })

      },
      'formFields.trackActivityIn.value': function trackActivityInValue(newValue, oldValue) {

        if (!this.formFields.isProxyContract.value) return

        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'proxyContractAddress',
          newValue: this.isProxyRequired,
          formName: this.formName,
        })
        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'proxyContractABIJSON',
          newValue: this.isProxyRequired,
          formName: this.formName,
        })

        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'implementationContractAddress',
          newValue: this.isImplementationRequired,
          formName: this.formName,
        })
        this.$store.commit('forms/SET_FIELD_REQUIRED', {
          fieldName: 'implementationContractABIJSON',
          newValue: this.isImplementationRequired,
          formName: this.formName,
        })

        this.$nextTick(() => {

          // @NOTE: we delay setting the value by one tick so the watchers in
          //  the useFormField composable have time to do their thing
          //
          //  otherwise the "valid" icon doesn't appear unless the user edits
          //  the field in some way
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'proxyContractAddress',
            formName: this.formName,
            newValue: this.isProxyRequired ? this.formFields.ambiguousContractAddress.value : '',
          })
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'implementationContractAddress',
            formName: this.formName,
            newValue: '',
          })

          if (this.isProxyRequired) {
            if (this.$refs.proxyContractABIJSON) this.$refs.proxyContractABIJSON.focus()
          } else if (this.isImplementationRequired) {
            if (this.$refs.implementationContractAddress) this.$refs.implementationContractAddress.focus()
          }

        })

      },
      'formFields.implementationContractABIJSON.value': function implementationContractABIJSONValue(newValue, oldValue) {
        this.addContractABIJSONFormFieldNote('implementationContractABIJSON', newValue)
      },
      'formFields.proxyContractABIJSON.value': function proxyContractABIJSONValue(newValue, oldValue) {
        this.addContractABIJSONFormFieldNote('proxyContractABIJSON', newValue)
      },
    },
    mounted() {

      // auto-focus the contract address field if a networkId was specified when
      //  opening the modal, otherwise auto-focus on the network input
      const focusElement = this.networkId
        ? this.$refs.ambiguousContractAddress
        : this.$refs.networkId

      if (focusElement) {
        focusElement.focus()
      }

    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      onBack() {

        this.errorMessage = null
        this.isUnverified = false

        const networkId = this.formFields.networkId.value
        const contractNickname = this.formFields.contractNickname.value
        const ambiguousContractAddress = this.formFields.ambiguousContractAddress.value

        this.$store.commit('forms/RESET_FORM', this.formName)

        // @NOTE: we delay setting the value by one tick so the watchers in
        //  the useFormField composable have time to do their thing
        //
        //  otherwise the "valid" icon doesn't appear unless the user edits
        //  the field in some way
        this.$nextTick(() => {
          this.$store.commit('forms/SET_FIELD_VALUE', {
            formName: this.formName,
            fieldName: 'networkId',
            newValue: networkId,
          })
          this.$store.commit('forms/SET_FIELD_VALUE', {
            formName: this.formName,
            fieldName: 'ambiguousContractAddress',
            newValue: ambiguousContractAddress,
          })
          this.$store.commit('forms/SET_FIELD_VALUE', {
            formName: this.formName,
            fieldName: 'contractNickname',
            newValue: contractNickname,
          })
        })

      },
      renameUserContract(shouldUpdate) {

        if (!this.existingUserContract) return null

        if (!shouldUpdate) {
          this.onSuccess(this.existingUserContract)
          this.close()
          return null
        }

        const newUserContractData = {
          id: this.existingUserContract.id,
          name: this.formFields.contractNickname.value,
        }

        this.isLoading = true

        return this.$store.dispatch('api/UPDATE_USER_CONTRACT', newUserContractData)
          .then((updatedUserContract) => {
            return this.$store.dispatch('user/REFRESH_USER_CONTRACTS')
              .then(() => {
                this.$store.dispatch('toast/CREATE_TOAST', {
                  text: 'Your contract has been renamed!',
                  type: 'success',
                })
                this.onSuccess(updatedUserContract)
              })
              .finally(() => {
                this.close()
              })
          })
          .catch((error) => {
            this.errorMessage = `Could not update ${this.contractNicknameLabel}.`
          })
          .finally(() => {
            this.isLoading = true
          })

      },
      addContractABIJSONFormFieldNote(fieldName, newValue) {

        this.$store.commit('forms/SET_FIELD_NOTE', {
          formName: this.formName,
          newValue: null,
          fieldName,
        })

        try {

          const { events, functions } = this.parseABI(newValue)

          let newNoteValue = null

          if (events.length !== 0 && functions.length !== 0) {
            newNoteValue = `Found ${events.length} events and ${functions.length} non-view/non-pure functions.`

          } else if (events.length !== 0) {
            newNoteValue = `Found ${events.length} events.`

          } else if (functions.length !== 0) {
            newNoteValue = `Found ${functions.length} non-view/non-pure functions.`

          } else {
            return
          }

          newNoteValue = `Found ${events.length} events and ${functions.length} non-view/non-pure functions.`

          this.$store.commit('forms/SET_FIELD_NOTE', {
            formName: this.formName,
            newValue: newNoteValue,
            fieldName,
          })

        } catch (error) {
          // do nothing
        }

      },
      checkForExistingContract() {

        this.existingUserContract = this.$store.state.user.userContracts
          .find((userContract) => {
            return (
              userContract.networkId === this.selectedNetworkOption.apiRecord.id
              && userContract.address.toLowerCase() === (this.formFields.ambiguousContractAddress.value).toLowerCase()
            )
          })

        if (!this.existingUserContract || this.existingUserContract.name !== this.formFields.contractNickname.value) {
          return
        }

        this.$store.dispatch('toast/CREATE_TOAST', {
          text: 'It looks like you already added that contract.',
          type: 'success',
        })

        this.onSuccess(this.existingUserContract)
        this.close()

      },
      submitForm() {

        this.checkForExistingContract()

        if (this.existingUserContract) return null

        // @NOTE: we don't just use the form's isLoading value since this form
        // is actually submitted manually
        this.isLoading = true

        this.$store.commit('forms/RESET_FORM_FIELD', {
          formName: 'createPatchForm',
          fieldName: 'userContractAddress',
        })
        this.$store.commit('forms/RESET_FORM_FIELD', {
          formName: 'createPatchForm',
          fieldName: 'userContractType',
        })
        this.$store.commit('forms/RESET_FORM_FIELD', {
          formName: 'createPatchForm',
          fieldName: 'userContractProxyEvents',
        })
        this.$store.commit('forms/RESET_FORM_FIELD', {
          formName: 'createPatchForm',
          fieldName: 'userContractImplementationEvents',
        })
        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: 'createPatchForm',
          fieldName: 'userContractProxyEvents',
          newOptions: [],
        })
        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: 'createPatchForm',
          fieldName: 'userContractImplementationEvents',
          newOptions: [],
        })

        let createContractRequest = null

        if (!this.isUnverified) {

          createContractRequest = this.$store.state.api.dispatch
            .post('/account/contracts', {
              networkId: this.formFields.networkId.value,
              name: this.formFields.contractNickname.value,
              address: this.formFields.ambiguousContractAddress.value,
            })

        } else {

          try {

            const createContractData = {
              networkId: this.formFields.networkId.value,
              name: this.formFields.contractNickname.value,
            }

            if (this.isProxyRequired) {
              createContractData.proxyAddress = this.formFields.proxyContractAddress.value
              createContractData.proxyABI = JSON.stringify(this.parseABI(this.formFields.proxyContractABIJSON.value).abi)
            }

            if (this.isImplementationRequired) {
              createContractData.implementationAddress = this.formFields.implementationContractAddress.value
              createContractData.implementationABI = JSON.stringify(this.parseABI(this.formFields.implementationContractABIJSON.value).abi)
            }

            createContractRequest = this.$store.state.api.dispatch
              .post('/account/contracts/unverified', createContractData)

          } catch (error) {
            // @NOTE: form validation should prevent this this.parseABI() from
            //  throwing errors at this point...
          }

        }

        return createContractRequest
          .then((createContractResponse) => {

            const newUserContract = createContractResponse.data

            this.$mixpanel.onReady((mixpanel) => {
              mixpanel.track('User Contract Created', {
                isUserContractVerified: !this.isUnverified,
                userContract: newUserContract,
                userContractName: newUserContract.name,
                userContractNetworkName: this.selectedNetworkOption.apiRecord.name,
              })
            })

            return this.$store.dispatch('user/REFRESH_USER_CONTRACTS')
              .then(() => {
                this.$store.dispatch('toast/CREATE_TOAST', {
                  text: 'Smart contract added!',
                  type: 'success',
                })
                this.onSuccess(newUserContract)
              })
              .finally(() => {
                this.close()
              })

          })
          .catch((error) => {

            if (
              error.response
              && error.response.status === 400
              && error.response.data !== 'Bad Request'
              && typeof error.response.data === 'string'
            ) {

              if (error.response.data.includes('not verified')) {
                this.isUnverified = true
                return
              }

              this.errorMessage = Array.isArray(error.response.data) ? error.response.data[0] : error.response.data
              return

            }

            this.errorMessage = this.$store.state.api.dispatchAPIErrors.unknown_error

          })
          .finally(() => {
            this.isLoading = false
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-6
    @apply space-x-2

    @apply flex
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-warning-500

</style>
